"use client";

import * as React from "react";

// @hooks
import { useTheme } from "next-themes";

export function ThemeToggle() {
  const { theme, setTheme } = useTheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <button
      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
      className="w-9 h-9 grid place-items-center rounded-full text-base hover:bg-fuchsia-600/10 dark:hover:bg-fuchsia-600/15"
    >
      {theme === "dark" && "🌙"}
      {theme === "light" && "☀️"}
    </button>
  );
}
